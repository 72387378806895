import React, { Component } from "react";
import PayPal from "./PayPal/PayPal";
import DonationCard from "./DonationCard/DonationCard";
import improveSchool1 from "../../../images/improve-school-1.jpeg";
import improveSchool2 from "../../../images/improve-school-2.jpeg";
import cleanDrinkingWater1 from "../../../images/clean-drinking-water.jpeg";
import cleanDrinkingWater2 from "../../../images/clean-drinking-water-2.jpeg";
import "./Donation.css";

export default class Donation extends Component {
	render() {
		return (
			<>
				<div className="container-fluid">
					<PayPal />
					<DonationCard
						imgSchool1={improveSchool1}
						imgSchool2={improveSchool2}
						donationTitle="IMPROVE SCHOOLS FOR THOUSANDS OF NUBIANS"
						donationInfo="The Sudanese government still maintains a heavy military presence in the Nuba Mountains. As a result, there is constant and perpetual conflict experienced by the Nuba people. Schools and educational services have not been developed. Currently, Children of Nuba people conduct classes under trees or in ragged grass-thatched houses. NPI is currently engaged in systematically constructing and renovating school facilities to improve the delivery of educational services.
        "
					/>
				</div>
			</>
		);
	}
}
