import * as firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";

var firebaseConfig = {
  apiKey: "AIzaSyB4wOP6cG2Cfo-MSsQDR0hvmBvUuR9j2oU",
  authDomain: "nubapi-e4352.firebaseapp.com",
  databaseURL: "https://nubapi-e4352.firebaseio.com",
  projectId: "nubapi-e4352",
  storageBucket: "nubapi-e4352.appspot.com",
  messagingSenderId: "102689973994",
  appId: "1:102689973994:web:75970021149e75feb39993",
  measurementId: "G-MEZ26T62XH",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
