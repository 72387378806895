import React, { Component } from "react";
// import firebase from "../firebase/config";
import "./ThankYouModal.css";

class ThankYouModal extends Component {
  state = {
    request: "",
    error: "",
    openReqModal: false,
  };

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // save the request data
    const { request } = this.state;
  };

  render() {
    const { title, message, closeRequestModal } = this.props;

    return (
      <div
        className={
          this.props.openReqModal ? "modal-request open" : "modal-request"
        }
      >
        <div className="modals">
          <h2>{title}</h2>
          <hr />
          <p>{message}</p>
          <hr />
          <button onClick={() => closeRequestModal()}>Ok</button>
        </div>
      </div>
    );
  }
}

export default ThankYouModal;
