import React, { Component } from "react";
import DatePicker from "react-datepicker";
import firebase from "../../../config/config";
import "./Volunteer.css";
class Volunteer extends Component {
  state = {
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    email: "",
    interest: "",
  };

  handleOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log(e.target.value);
  };

  handleVolunteerRequest = (e) => {
    e.preventDefault();
    console.log();

    // save the request data
    var addVolunteerRequests = firebase
      .functions()
      .httpsCallable("addVolunteerRequests");

    addVolunteerRequests({ text: this.state })
      .then(() => {
        // clear form field values
        this.setState({
          firstName: "",
          lastName: "",
          birthDate: "",
          gender: "",
          email: "",
          interest: "",
        });

        this.props.openRequestModal("", "volunteer", "success");
      })
      .catch((error) => {
        if (error.message === "INTERNAL") {
          console.log("Don't worry");
          this.setState({
            firstName: "",
            lastName: "",
            birthDate: "",
            gender: "",
            email: "",
            interest: "",
          });
          this.props.openRequestModal("", "volunteer", "success");
          return;
        } else {
          const msg = error.message;
          this.props.openRequestModal("", "error", msg);
        }
      });
  };
  render() {
    return (
      <div className="volunteer-container">
        <div className="volunteer-image">
          <img src={this.props.imagePic} alt="" />
        </div>

        <div className="volunteer-form">
          <p>
            By volunteering with us, you are donating your time, effort and
            services to the people of Nuba mountains. Although you may not be
            entitled to finacial gains but our team will certainly give the best
            experience to challenge your humanitarian dream.
          </p>

          <p>
            For more information <a href="#Contact">contact us</a> here at your
            convinience and we will get right back to you.
          </p>
          <form onSubmit={this.handleVolunteerRequest}>
            <label htmlFor="firstName">
              <p>First Name</p>
              <input
                onChange={(e) => this.handleOnChange(e)}
                name="firstName"
                type="text"
                placeholder="Stephen"
                value={this.state.firstName}
                required
              />
            </label>
            <label htmlFor="lastName">
              <p>Last Name</p>
              <input
                onChange={(e) => this.handleOnChange(e)}
                name="lastName"
                type="text"
                placeholder="Ladu"
                value={this.state.lastName}
                required
              />
            </label>
            <label htmlFor="birthDate">
              <p>Date of Birth</p>
              <input
                onChange={(e) => this.handleOnChange(e)}
                name="birthDate"
                type="text"
                placeholder="MM/DD/YYYY e.g 23/03/1982"
                value={this.state.birthDate}
                required
              />
            </label>
            <label htmlFor="gender">
              <p>Gender</p>
              <input
                onChange={(e) => this.handleOnChange(e)}
                name="gender"
                type="text"
                placeholder="Gender"
                value={this.state.gender}
                required
              />
            </label>
            <label htmlFor="email">
              <p>Email</p>
              <input
                onChange={(e) => this.handleOnChange(e)}
                name="email"
                type="email"
                placeholder="Email"
                value={this.state.email}
                required
              />
            </label>
            <label htmlFor="message">
              <p>Message</p>
              <textarea
                onChange={(e) => this.handleOnChange(e)}
                name="interest"
                type="text"
                placeholder="Tell us your interest, academic background, etc"
                value={this.state.interest}
                required
              />
            </label>
            <button
              // onClick={(e) => this.props.openRequestModal(e, "volunteer")}
              className="btn red"
              type="submit"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Volunteer;
