import jumaArtema from "../../../images/team/juma-artema.jpg";
import laduArtema from "../../../images/team/ladu-artema.jpg";
import walifa from "../../../images/team/walifa.jpeg";
import mohamedDoudi from "../../../images/team/mohamed-doudi.jpeg";
import adam from "../../../images/team/adam.jpeg";
import el_bashir from "../../../images/team/el-bashir.jpeg";
import momon from "../../../images/team/egbalmomon.jpeg";

export const teamInfo = [
	{
		name: "Adam Ismail Alaihaimier",
		title: "Chair of the Board",
		pic: adam,
		bio: "",
		website: "https://ubtransportmn.com/",
	},
	{
		name: "Ladu Artema",
		title: "Treasurer/Finance Officer",
		pic: laduArtema,
		bio: "",
	},
	{
		name: "Mohamed Doudi",
		title: "Environmental Consultant",
		pic: mohamedDoudi,
		bio: "",
	},
	{
		name: "Egbal Adam Momon",
		title: "Women Affairs",
		pic: momon,
		bio: "",
	},
	{
		name: "Walid Safwat",
		title: "Director of Operations",
		pic: walifa,
		bio: "",
	},
	{
		name: "Mohamed El-Bashir",
		title: "Member",
		pic: el_bashir,
		bio: "",
		website: "https://www.el-bashirlaw.com/",
	},
	{
		name: "Juma Artema",
		title: "Member",
		pic: jumaArtema,
		bio: "",
		website: "https://www.anikahomecare.com/",
	},
];
