import React, { Component } from "react";
import teacherinclass from "../../../images/npi-education.jpeg";
import studentNairobi from "../../../images/students-nairobi.jpeg";
import "./Stories.css";

class Stories extends Component {
  render() {
    return (
      <>
        <div className="container-fluid stories-container pt-4 pb-5 mb-5">
          <div className="row story-grid justify-content-center">
            <div className="col-12">
              <h2 className="story-title pt-3 pb-3">
                What matters more than educating the future generation
              </h2>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 story-image-box">
              <img className="img-fluid" src={teacherinclass} alt="learning" />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6  story-text-box">
              <h4 className="story-highlight">
                A team of volunteer teacher interacting school pupil. We are
                currently seeking for well wisher to support the education of
                Nubian children.
              </h4>
              <p>
                Nuba Peace Initiative Inc. will work with local communities to
                identify locations, and engage local and international trainers
                to train and educate the Nubian population in order to combact
                illitracy. NPI will also work to recruit and train teachers,
                collect books and solicit donations from American friends to
                help pay teachers.
              </p>
              <h4 className="story-author">NPI Cares.</h4>
              <h6 className="story-author-title">|Support good cause|</h6>

              <div className="read-more">
                <p>Read More...</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid stories-container pt-4 pb-5">
          <div className="row story-grid justify-content-center">
            <div className="col-12">
              <h2 className="story-title pt-3 pb-3">
                Over 15 students toured Kenyan Schools and Universities
              </h2>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 story-image-box">
              <img
                className="img-fluid"
                src={studentNairobi}
                alt="Students Touring Nairobi"
              />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6  story-text-box">
              <h4 className="story-highlight">
                Investing into the future Nubian Youth
              </h4>
              <p>
                Nuba Peace Initiative facilitated a capacity building tour of
                three months for selected members of Nuba Community from South
                Kordofan state, the Republic of the Sudan. The tour was
                primarily aimed at inculcating sustainable livelihood principles
                to five young professionals.
              </p>

              <p>
                This was in order to increase and inspire the effectiveness of
                their engagement, boosting their capacity to build an effective
                and sustainable society particularly at the state level and,
                therefore contribute to improving the involvement and ownership
                local development agenda.
              </p>
              <p>
                The tour was highly interactive, involving presentations and
                discussions, with inputs from the several Kenyan experts and
                institutions, as well as practical workshops, featuring
                exchanges, group exercises, and learning on societal engagement
                and cross-sectoral working.
              </p>

              <div className="read-more">{/* <p>Read More...</p> */}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Stories;
