import React, { Component } from "react";
import "./Events.css";

export class Events extends Component {
	render() {
		const getEventDate = () => {
			const d = new Date();
			const month = d.getMonth();
			const year = d.getFullYear();

			if (month > 8) {
				return `23 Sept ${year + 1}`;
			}
			return `23 Sept ${year}`;
		};
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-8 col-xs-12">
						<div className="main">
							<div className="main-image-container">
								<h6>Upcoming Event</h6>
								<h4>Annual Peace & Reconciliation Event</h4>
								<h5>{getEventDate()}, Minneapolis, MN, USA</h5>
							</div>
						</div>
					</div>

					<div className="col">
						<div className="side-info">
							<h3>Latest and top Success Stories</h3>

							<div className="stories">
								<a href="#">
									<h5>
										Food Distribution to the Vulnerable affected by COVID-19
									</h5>
								</a>

								<p>
									To fight hunger during the COVID-19 pandemic, NPI has
									distributed food items to the people of Nuba Mountains. Over
									hundreds of families benefited from this program.
								</p>
							</div>

							<div className="stories">
								<a href="#">
									<h5>
										About 140 local farmers have been identified, selected for
										training in modern farming
									</h5>
								</a>

								<p>
									Due to COVID-19, we have suspended the training program termly
									local farmers training until further notice
								</p>
							</div>

							<div className="stories">
								<a href="#">
									<h5>
										Carried Empowerment programmes for young girls in Nuba
										mountains
									</h5>
								</a>

								<p>
									NPI to launch support for girl child sex education to support
									adolence girls.
								</p>
								<p>
									The program will also distribution sanitary pads for
									menstruating girls in schools and higher education centers.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Events;
