import React, { Component } from "react";

import slide_0 from "../../../images/slides/slide-0.JPG";
import slide_1 from "../../../images/slides/slide-1.jpg";
import slide_2 from "../../../images/slides/slide-2.jpg";
import slide_3 from "../../../images/slides/slide-3t.JPG";
import slide_4 from "../../../images/slides/slide-4a.jpeg";
import slide_5 from "../../../images/slides/slide-5.jpg";
import slide_6 from "../../../images/slides/slide-6.jpg";

import "./Home.css";

export class Home extends Component {
	constructor() {
		super();
		this.state = {
			imageSlide: slide_0,
			fade: false,
			id: "",
		};
		this.handleFade = this.handleFade.bind(this);
		this.slideImages = this.slideImages.bind(this);
	}

	componentDidMount() {
		const slideImage = [
			{ image: slide_0, id: "slide_0" },
			{ image: slide_1, id: "slide_1" },
			{ image: slide_2, id: "slide_2" },
			{ image: slide_3, id: "slide_3" },
			{ image: slide_4, id: "slide_4" },
			{ image: slide_5, id: "slide_5" },
			{ image: slide_6, id: "slide_6" },
		];
		var i = 1;

		this.imageID = setInterval(() => {
			if (i === slideImage.length) {
				this.slideImages(slideImage[0].image);
				i = 0;
			} else {
				this.slideImages(slideImage[i].image);
			}
			i++;
			// console.log(i);
			this.setState({ id: i });
			this.handleFade();
		}, 4000);
	}

	componentWillUnmount() {
		clearInterval(this.imageID);
	}

	slideImages(image) {
		this.setState({
			imageSlide: image,
		});
	}

	handleFade() {
		this.setState({ fade: !this.state.fade });
	}

	render() {
		const { imageSlide, fade, id } = this.state;
		// console.log(id);
		return (
			<div className="home-slide-container">
				<div className="image-container">
					<img
						className={fade ? "fade-in" : "fade-out"}
						src={imageSlide}
						alt="images-slide"
					/>
				</div>
				{/* <div className="vision-statement">
          <h3>We strive to excellency so the disadvantaged can also thrive.</h3>
        </div> */}
			</div>
		);
	}
}

export default Home;
