import cleanWater from "../../../images/clean-water.jpeg";
import agriculture from "../../../images/agriculture.jpeg";
import education from "../../../images/boys-in-class.jpeg";
import scholarship from "../../../images/scholarship.jpeg";
import communityHealth from "../../../images/health-care.jpeg";

export const cardInfo = [
	{
		title: "Community Health Center",
		image: communityHealth,
		briefContent:
			"NPI strives to alleviate health crisis in the Nuba area. We are establishing different health programs including the recent plan to build a Maternal Clinic for vulnerable women. We hope the establishment of maternal clinic will cut down risks and maternal mortality rate by 30%. We are currently open to work with different health practitioner and we welcome any contribution.",

		linkTo: "/community-health",
		nameOfClass: "water-sanitation",
	},
	{
		title: "Water and Sanitation",
		image: cleanWater,
		briefContent:
			"The estimated population of Sudan was 43 million people as of 2018. Water and sanitation are essential for social stability. The water and sanitation project accounts of the Nuba Peace Initiative are for the benefit of some of the 3.5 million people in the Nuba Mountains region, specifically the population in Deling area. Our strategy to provide clean water will include teaching the local community methods of water purification.",

		linkTo: "/water-and-sanitation",
		nameOfClass: "water-sanitation",
	},
	{
		title: "Agricultural Production",
		image: agriculture,
		briefContent:
			"Although the region remains seized in ongoing conflict, we continue to carry out agricultural projects in the Nuba Mountains. In Liri, South Kordofan, and other parts of Sudan, we continue to provide essential agricultural services. NPI has over 1000 acres of land. Currently, we run agricultural training programs for local farmers, as well as distribution of drought-resistant seeds and agricultural tools. Our strategic plan encourages and supports local farms to increase agricultural production to ensure food security in Nuba communities and the entire nation of Sudan.",
		linkTo: "/water-and-sanitation",
		nameOfClass: "agriculture",
	},
	{
		title: "Elementary Education – A Secure Bright Future",
		image: education,
		briefContent:
			"The Sudanese government still maintains a heavy military presence in the Nuba Mountains. As a result, there is constant and perpetual conflict experienced by the Nuba people. Schools and educational services and facilities are not well developed. Currently, many children of Nuba people attend classes under trees or in ragged grass-thatched houses. NPI is currently engaged in systematically constructing and renovating school facilities to improve the delivery of educational services.",
		linkTo: "education",
		nameOfClass: "education",
	},
	{
		title: "Scholarship for Advance Education",
		image: scholarship,
		briefContent:
			"NPI is engaged in the provision of scholarships for students to study both in Sudan and abroad. Currently about eight students with these scholarships are enrolled to study in Kenyan schools and universities in Nairobi (3), with another six in Addis Ababa, Ethiopia, and over 14 in Sudan.",
		nameOfClass: "scholarship",
	},
];
