import React, { Component } from "react";

class TeamCard extends Component {
	render() {
		const { name, profilePic, teamBio, teamTitle, website } = this.props;
		return (
			<div className="team-card-item-container">
				<div className="team-image">
					<img className="team-card-image" src={profilePic} alt={teamTitle} />
				</div>
				<div className="team-card-text-container">
					<h1 className="team-name">{name}</h1>
					<h1 className="team-title">{teamTitle}</h1>
					<p className="bio">{teamBio}</p>
					{website ? (
						<a target="_blank" href={website}>
							Learn More
						</a>
					) : null}
				</div>
			</div>
		);
	}
}

export default TeamCard;
