import React, { Component } from "react";
// import "./ProgramCardItem.css";

class ProgramCardItem extends Component {
  render() {
    const { cardTitle, cardImage, cardBriefMessage, cardLink } = this.props;
    return (
      <>
        <div className="row container mb-5 image-text-box">
          <div className="col-sm-6 card-text-container">
            <h1 className="title">{cardTitle}</h1>
            <p className="brief-content">{cardBriefMessage}</p>
            <a href={cardLink} className="links-to-follow">
              Learn more...
            </a>
          </div>

          <div className="col-sm-6 programCard-image">
            <img className="img-fluid" src={cardImage} alt={cardTitle} />
          </div>
        </div>
      </>
    );
  }
}

export default ProgramCardItem;
