import React, { Component } from "react";
import "./About.css";

export class About extends Component {
  render() {
    return (
      <div className="about-us container-fluid">
        <h1 className="vision">Vision</h1>
        <p>Vision statement coming soon!</p>
        <h1 className="mission">Mission</h1>
        <p>
          Our mission is to support and assist refugees, immigrants, the
          economically challenged, and underserved to become self-sufficient
          productive members of their communities.
        </p>
      </div>
    );
  }
}

export default About;
