import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "materialize-css/dist/css/materialize.min.css";
// import NavBar from "./components/NavBar/NavBar";
import ThankYouModal from "./components/Common/Modals/ThankYouModal";
import NavBar from "./Reusables/NavBar/NavBar";
import Content from "./components/Content/Content";
import Footer from "./components/Footer/Footer";

import "./Reusables/MainStyle.css";
import "./App.css";

class App extends Component {
	state = {
		openReqModal: false,
		title: "",
		message: "",
	};
	openRequestModal = (e, modalType) => {
		console.log("opening request modal");

		//MODAL PUPOUT CONTROL
		// add the different modal here.
		if (modalType === "contact") {
			// do stuff here
			this.setState({
				openReqModal: true,
				title: "Thank you",
				message:
					"We are very glad that you have taken the time to contact us, Adam will get back to you as soon as possible.",
			});
		} else if (modalType === "volunteer") {
			// do sfuff here
			this.setState({
				openReqModal: true,
				title: "Success!",
				message:
					"Thank you for expressing interest to build your career with us, Adam will get back to you soon on this matter.",
			});
		}
	};

	closeRequestModal = () => {
		console.log("closing request modal");
		// close the request modal
		this.setState({
			openReqModal: false,
		});
	};

	closeModal = () => {
		this.setState({ openReqModal: false });
		console.log("You clicked html body so I am closing the popup");
	};

	render() {
		const { title, openReqModal, message } = this.state;
		return (
			<div className="App">
				<NavBar />
				<ThankYouModal
					title={title}
					message={message}
					openReqModal={openReqModal}
					closeRequestModal={this.closeRequestModal}
				/>
				<Content openRequestModal={this.openRequestModal} />
				<Footer />
			</div>
		);
	}
}

export default App;
