import React, { Component } from "react";
import ProgramCardItem from "./ProgramCardItem/ProgramCardItem";
import { cardInfo } from "./cardInfo";
import "./Programs.css";

class ProgramCards extends Component {
	render() {
		return (
			<>
				<div className="card-list">
					{cardInfo.map((item, index) => (
						<div key={index} className={item.nameOfClass}>
							<ProgramCardItem
								cardImage={item.image}
								cardTitle={item.title}
								cardBriefMessage={item.briefContent}
								cardLink={item.linkTo}
							/>
						</div>
					))}
				</div>
			</>
		);
	}
}

export default ProgramCards;
