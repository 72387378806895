import React, { Component } from "react";
import "./Footer.css";

export class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="contact-info">
          Contact Information
          <div className="contacts">
            <h5>Head Quarter</h5>
            <h6>6603 Queen Ave S, Richfield, MN 55423, US</h6>
            <h5>Field Offices</h5>
            <h6>214 Blocks 1 East Airport Elobeid,Sudan</h6>
            <h5>Phone</h5>
            <h6>+1 (952) 200-4210</h6>
            <h5>Fax</h5>
            <h6>+1 (800)-504-9735</h6>
            <h5>E-mail</h5>
            <h6>nubaview@gmail.com</h6>
          </div>
        </div>
        <div className="hours-operation">
          Hours of Operation Monday – Friday 9:00 AM – 6:00 PM Saturday 10: 00
          AM – 2:00 PM Sunday Closed
        </div>

        <div className="copy-right">© Copyright 2020 Nuba Peace Initiative</div>
      </div>
    );
  }
}

export default Footer;
