import React, { Component } from "react";
import firebase from "../../../config/config";

import "./Contact.css";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      email: "",
      message: "",
    };
    this.handleContactUs = this.handleContactUs.bind(this);
  }

  handleChange = (e) => {
    // e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleContactUs(e) {
    e.preventDefault();
    const { subject, email, message } = this.state;
    console.log({ subject, email, message });

    var sendEmails = firebase.functions().httpsCallable("sendEmails");
    sendEmails({
      email,
      subject,
      message,
    })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        if (error.message === "INTERNAL") {
          console.log(error);
        } else {
          console.log(error.message);
        }
      });

    // clear the field
  }
  render() {
    return (
      <div className="contact-container">
        <div className="contact-form">
          <h1>Leave a message</h1>
          <form onSubmit={(e) => this.handleContactUs(e)}>
            {/* <label htmlFor="name">
              <p>Your name</p>
              <input
                name="name"
                type="text"
                placeholder="John Lado"
                // value={this.state.name}
                onChange={(e) => this.handleChange(e)}
              />
            </label> 
            */}
            <label htmlFor="email">
              <p>Email</p>
              <input
                name="email"
                type="email"
                placeholder="example@gmail.com"
                // value={this.state.email}
                onChange={(e) => this.handleChange(e)}
                required
              />
            </label>
            <label htmlFor="subject">
              <p>Subject</p>
              <input
                required
                name="subject"
                type="text"
                placeholder="Enter subject"
                // value={this.state.subject}
                onChange={(e) => this.handleChange(e)}
              />
            </label>
            <label htmlFor="message">
              <p>Message</p>
              <textarea
                // value={this.state.message}
                onChange={(e) => this.handleChange(e)}
                name="message"
                type="text"
                placeholder="Write your message"
                required
                // to preserve white space entered by the user, do this =>...
                style={{
                  whiteSpace: "pre-line",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                }}
              />
            </label>
            <button
              onClick={(e) => this.props.openRequestModal(e, "contact")}
              className="btn red"
              type="submit"
            >
              Send
            </button>
          </form>
        </div>
        <div className="contact-info-box">
          <h1>Even more contact info to get to us quickly</h1>
          <div className="contact-text">
            <div className="hours-operation">
              <h4>
                Visit us at one of our offices duration this operation hours
              </h4>
              <ul>
                <li> Monday-Friday 9:00 AM – 6:00 PM </li>
                <li>Saturday 10: 00 AM – 2:00 PM</li>
                <li>Sundays we are closed</li>
              </ul>
            </div>
            <div className="contacts">
              <h5>Our Head Quarter</h5>
              <p>6603 Queen Ave S, Richfield, MN 55423, US</p>
              <h5>Our Field Office</h5>
              <p>214 Blocks 1 East Airport Elobeid, Sudan</p>
              <h5>Phone</h5>
              <p>+1 (952) 200-4210</p>
              <h5>Fax</h5>
              <p>+1 (800)-504-9735</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
