import React, { Component } from "react";
import Titles from "../Common/Titles/Titles";
import Home from "../Pages/Home/Home";

import Events from "../Pages/Event/Events";

import Programs from "../Pages/Programs/Programs";
import programPicture from "../../images/program.jpeg";

import Stories from "../Pages/Stories/Stories";
import storyPicture from "../../images/storyPicture.jpeg";

import Donation from "../Pages/Donation/Donation";
import donationPicture from "../../images/donationTiltePicture.jpg";

import Volunteer from "../Pages/Volunteer/Volunteer";
import volunteerPic from "../../images/volunteer.jpeg";

import About from "../Pages/About/About";
import Team from "../Pages/Team/Team";
import npiTeam from "../../images/npi-team.jpeg";

import Contact from "../Pages/Contact/Contact";
import contactPic from "../../images/contact.jpeg";
import "./Content.css";

export class Content extends Component {
	render() {
		return (
			<div className="content-box">
				<div className="reposition-under-nav-top"></div>
				<div className="home-flash-container">
					<a name="home">
						<Home />
					</a>
				</div>
				{/* <div className="reposition-under-nav-top">Break</div> */}
				<div className="event-container">
					<a name="events">
						<Events />
					</a>
				</div>
				<a name="programs">
					<Titles
						topTitle="We are solution to the numerous"
						bottomTitle="CHALLENGES NUBIANS ARE FACING TODAY"
						titlePicture={programPicture}
						shortSubtitle="A peak Rationale for NPI"
						shortMessage="The Nuba Peace Initiative (NPI) operates as a non-profit organization dedicated to helping Nuba peoples to survive the war and supporting survivors to meet their basic needs. Support from international humanitarian agencies in the Nuba Mountains is needed to provide aid in the form of medicine, clothing, shelter and schools for refugees, especially orphans, widows and the elderly, to help restore them to peace and normal lives. The Nuba Peace Initiative organization is also committed to educating the world about the problems that Nuba people face to increase awareness of their devastating situation through international media. Ultimately, the goal must be to stop the war and restore peace and prosperity."
					/>
					<Programs />
				</a>
				<a name="stories">
					<Titles
						topTitle="Top Success Stories"
						bottomTitle="DEMONSTRATING POSITIVE IMPACTS ON PEOPLE'S LIVES"
						titlePicture={storyPicture}
						shortSubtitle="RESTORATION OF PEACE AND HARMONY."
						shortMessage="We have been organizing peacebuilding communal events, encouraging communal dialogue and promoting a transparent and democratic government in the country, as partially evident in the downfall of the dictatorial government of Omar Albashir."
					/>
					<Stories />
				</a>

				<a name="volunteers">
					<Titles
						topTitle="Volunteer with us"
						bottomTitle="BUILD YOUR CAREER AND MAKE A DIFFERENCE"
						shortMessage="Should you be interested, please don't hesitate to fill in the following preliminary volunteer form stating clearly in which area you would like help"
						shortSubtitle="ARE YOU INTERESTED IN VOLUNTEERING WITH US?"
						titlePicture={volunteerPic}
					/>

					<Volunteer openRequestModal={this.props.openRequestModal} />
				</a>
				<a name="donate">
					<Titles
						topTitle="I Support Nuba Peace Initiative."
						bottomTitle="DONATE FOR A BETTER FUTURE FOR NUBA PEOPLE"
						titlePicture={donationPicture}
						shortSubtitle="Donate"
						shortMessage="Donate to support life and restore hope to the people of Nuba. We have extensively researched the critical issues that need urgent support from philanthropists and sponsors."
					/>
					<Donation />
					{/* <Donation
            donationPicture={[cleanDrinkingWater1, cleanDrinkingWater2]}
            donationTitle="To provide clean drinking water"
            donationInfo="Nuba Peace Initiative, Inc. is coordinating an effort to provide clean
          water via Boreholes, water pumps and water harvesting infrastructures
          to help reestablish destroyed villages. The Initiative will drill
          wells and boreholes in villages throughout Nuba Mountains supplying thousands of
          families with a source of clean, safe drinking water.
          "
          />  */}
				</a>
				<a name="about">
					<Titles
						topTitle="About Us"
						bottomTitle="Nuba Peace Initiative (NPI)"
						titlePicture={npiTeam}
						shortSubtitle="Did you know?"
						shortMessage="NPI is a non-profit organization dedicated to helping the Nuba people survive the impacts of war. Our services support survivors as they strive to meet their basic needs. International humanitarian agencies in the Nuba Mountains region play major roles by providing aid in the forms of medicine, clothing, and shelters. We help restore schools for refugees, support orphanages, and provide work training for widows and care for the elderly. NPI has a presence in needed areas and our on-site partners provide the services that we support. NPI is also committed to educating the world about the Nuba people and increasing awareness of the devastating situation. NPI has been campaigning and will continue doing so through international media to bring an end to the war and help restore peace and prosperity. Our goal is to bring together Nubian Sudanese leaders, activists, friends, experts and community elders.
            "
					/>
					<About />
					<Team />
				</a>
				<a name="contact">
					<Titles
						topTitle="Important Contact Information"
						bottomTitle="Easily contact us"
						shortMessage="If you would like to volunteer in any capacity, kindly click on the link to learn more about the application process. For all other purposes, use the following contact information. We will always get back to you within a reasonable amount of time."
						titlePicture={contactPic}
					/>
					<Contact openRequestModal={this.props.openRequestModal} />
				</a>
			</div>
		);
	}
}

export default Content;
