import React, { Component } from "react";
import logo from "../../images/npi-logo-best.png";
import "./NavBar.css";

// inpired by https://www.youtube.com/watch?v=gXkqy0b4M5g

export default class NavBar extends Component {
  state = {
    itemClass: false,
    activeNav: false,
    activeNavItem: "Home",
    activateMobile: false,
  };

  componentDidMount() {
    // Auto initialize all the things!
    // M.AutoInit();
  }

  // Replace, remove or add new nav items...
  navbarItems = [
    { navName: "Home", link: "home" },
    { navName: "Events", link: "events" },
    { navName: "Programs", link: "programs" },
    { navName: "Stories", link: "stories" },
    { navName: "Volunteers", link: "volunteers" },
    { navName: "Donate", link: "donate" },
    // { navName: "Media", link: "media" },
    { navName: "About Us", link: "about" },
    { navName: "Contact Us", link: "contact" },
  ];

  navSlide = () => {
    const { itemClass, activeNav } = this.state;
    this.setState({
      itemClass: !itemClass,
      activeNav: !activeNav,
      activateMobile: true,
    });
  };

  handleNavLinkClick = (navItem) => {
    if (this.state.activateMobile) {
      this.setState({
        activeNavItem: navItem,
      });
      this.navSlide();
    } else {
      this.setState({
        activeNavItem: navItem,
      });
    }
  };

  render() {
    const { activeNav, itemClass, activeNavItem } = this.state;
    const delay = this.navbarItems.map(
      (el, index) => index / (this.navbarItems.length + 20) + 0.1
    );
    const aniName = activeNav ? "navLinkFadeIn" : "navLinkFadeOut";

    return (
      <div>
        <nav>
          {/* Burger Icon */}
          <div onClick={this.navSlide} className="burger">
            <div className={`${!itemClass ? "line-1" : "toggle-line-1"}`}></div>
            <div className={`${!itemClass ? "line-2" : "toggle-line-2"}`}></div>
            <div className={`${!itemClass ? "line-3" : "toggle-line-3"}`}></div>
          </div>
          <div className="logo">
            <h4>
              <a href="#home">
                <div className="log">
                  <img src={logo} alt="npi-logo" />
                </div>
                <div className="log-text">
                  <h2>Nuba</h2>
                  <h1>Peace Initiative</h1>
                </div>
              </a>
            </h4>
          </div>

          <ul
            className={`${!itemClass ? "nav-links" : " nav-active nav-links"}`}
          >
            {this.navbarItems.map(({ navName, link }, i) => (
              <li onClick={() => this.handleNavLinkClick(navName)} key={i}>
                <a
                  style={{
                    opacity: !activeNav ? 1 : 0,
                    animation: `${aniName} 0.4s ease forwards ${delay[i]}s`,
                  }}
                  href={`#${link}`}
                >
                  <span
                    className={
                      activeNavItem === navName ? "active-nav-item" : " "
                    }
                  >
                    {navName}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    );
  }
}
