import React, { Component } from "react";

export class DonationCard extends Component {
  render() {
    const { imgSchool1, imgSchool2, donationTitle, donationInfo } = this.props;
    return (
      <div className="donation-box container-fluid">
        <div className="row">
          <div className="col-12">
            <h1 className="title-donation">{donationTitle}</h1>
          </div>
          <div className="col-sm-6 mb-1 donation-img one">
            <img className="img-fluid" src={imgSchool1} alt={donationTitle} />
          </div>
          <div className="col-sm-6 mb1 donation-img two">
            <img className="img-fluid" src={imgSchool2} alt={donationTitle} />
          </div>
          <div className="col-sm-12">
            <p className="lead"> {donationInfo}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default DonationCard;
