import React, { Component } from "react";
// import pictureImage from "../../../images/boy-wearing-yellow-crew-neck-shirt-and-black-short-3207532.jpg";
// import kidface from "../../../images/npi-kid-face.jpeg";
import "./Titles.css";

class Titles extends Component {
  render() {
    const {
      topTitle,
      bottomTitle,
      shortSubtitle,
      shortMessage,
      titlePicture,
    } = this.props;
    return (
      <div className="titles-container">
        <div className="title-box">
          <div className="container-fluid">
            <h1 className="top-title">
              {topTitle ? topTitle : "Enter top title"}
              <hr />
            </h1>
            <h1 className="bottom-title">
              {bottomTitle ? bottomTitle : "Enter Bottom title"}
            </h1>
          </div>
        </div>
        <div className="title-message">
          <div className="image-container">
            <img className="round-picture" src={titlePicture} alt={topTitle} />
          </div>

          <div className="description-large-hide-on-small">
            <h1>{shortSubtitle}</h1>
            <p>{shortMessage}</p>
            {/* <a href="#">
              <h5>Learn more...</h5>
            </a> */}
          </div>
        </div>
        <div className="container-fluid">
          <div className="description-small-show-on-small">
            <h1>{shortSubtitle}</h1>
            <p>{shortMessage}</p>
            {/* <a href="#">
              <h5>Learn more...</h5>
            </a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Titles;
