import React, { Component } from "react";

export class PayPal extends Component {
  render() {
    return (
      <div>
        <div className="row pt-4 pb-4">
          <div class="col-12 col-md-6">
            <h2 className="h2">
              Thank you so much for supporting Nuba Peace Initiative in advance!
            </h2>
            <p className="lead">
              With your donation, we can do alot to improve the challenges of
              Nubian
            </p>
          </div>
          <div class="col-12 col-md-6">
            <h4>Click the "Donate" button below to donate</h4>
            <div className="donation-paypal">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="3CXZKPHLJ4SG6"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PayPal;
