import React, { Component } from "react";
import TeamCard from "./TeamCard";
import { teamInfo } from "./teamInfo";
import dummyProfile from "../../../images/dumy-profile.png";
import "./Team.css";

class Team extends Component {
	render() {
		return (
			<div className="team-box-container">
				<div className="team-info">
					<h1>Meet the Team</h1>
					<p>
						We come together from various backgrounds to solve Nubian. We are
						from diverse background, each dedication to working to restore peace
						and harmony in variety of ways. Currently our team not just hard
						working people but are patriotic and each contributing to support
						the program at NPI
					</p>
					<p></p>
				</div>
				{teamInfo.map((team, index) => (
					<div key={index} className="team-item-box">
						<TeamCard
							name={team.name}
							teamTitle={team.title}
							profilePic={team.pic ? team.pic : dummyProfile}
							teamBio={team.bio}
							website={team.website}
						/>
					</div>
				))}
			</div>
		);
	}
}

export default Team;
